import React, { useEffect, useRef, useState } from "react";
import { Layout, Breadcrumb, Typography, Checkbox, Collapse } from "antd";
import "./App.css";
import logo from "./assets/vi.jpg";
import { RobotOutlined } from "@ant-design/icons";

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const App = () => {
  const [isChecked, setIsChecked] = useState(true);
  // const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCheckboxDisabled] = useState(false);

  const handleCheckboxChange = (e) => {
    // eslint-disable-next-line no-lone-blocks
    {
      isChecked ? setIsChecked(false) : setIsChecked(true);
    }
  };

  const terms = (
    <>
      <Title level={4}>1. Introduction</Title>
      <Paragraph>
        Welcome to "Vi" service, the smart AI that can integrate with WhatsApp.
        "Vi" experts in various fields, including Google Analytics, Facebook,
        and Instagram Ads, Google Ads, Copywriting, and SEO. Their expertise
        ranges from organizing, tracking, and surfacing data trends to creating
        and optimizing ad campaigns and paid search landing pages.
      </Paragraph>
      <Title level={4}>2. Acceptance of Terms</Title>
      <Paragraph>
        By accessing or using "Vi" service, you agree to be bound by these Terms
        of Use. If you do not agree to these Terms of Use, you may not access or
        use "Vi" service.
      </Paragraph>
      <Title level={4}>3. Use of Service</Title>
      <Paragraph>
        You must be a registered user to access and use "Vi" service. You can
        use "Vi" to get insights into your website's key performance metrics,
        competitive trending, and to create forecasts using operational and
        statistical tools and models. You can also use "Vi" to run Facebook and
        Instagram Ads, Google Ads, Copywriting, and SEO.
      </Paragraph>
      <Title level={4}>4. User Content </Title>
      <Paragraph>
        "Vi" allows you to upload User Content in the form of data, information,
        and other materials. You are solely responsible for your User Content
        and you agree that "Vi" has no responsibility for the accuracy,
        completeness, or legality of your User Content.
      </Paragraph>
      <Title level={4}>5. Intellectual Property </Title>
      <Paragraph>
        "Vi" service and all content included on the site, such as text,
        graphics, logos, images, audio clips, and software, are the property of
        "Vi" or its content suppliers and are protected by international
        copyright laws.
      </Paragraph>
      <Title level={4}>6. Privacy Policy </Title>
      <Paragraph>
        "Vi" respects your privacy and is committed to protecting your personal
        information. Please refer to our Privacy Policy for more information on
        how we collect, use, and disclose your personal information.
      </Paragraph>

      <Title level={4}>7. Disclaimer of Warranties</Title>
      <Paragraph>
        "Vi" service is provided on an as-is and as-available basis. "Vi" makes
        no representations or warranties of any kind, express or implied,
        regarding the use or the results of this service in terms of its
        correctness, accuracy, reliability, or otherwise.
      </Paragraph>

      <Title level={4}>8. Limitation of Liability</Title>
      <Paragraph>
        Under no circumstances shall "Vi" or its affiliates, officers,
        directors, employees, agents, suppliers, or licensors be liable for any
        indirect, incidental, special, consequential, or exemplary damages
        arising out of or in connection with your use of "Vi" service.
      </Paragraph>

      <Title level={4}>9. Indemnification</Title>
      <Paragraph>
        You agree to indemnify and hold harmless "Vi" and its affiliates,
        officers, directors, employees, agents, suppliers, or licensors from any
        claim or demand, including reasonable attorneys' fees, made by any third
        party due to or arising out of your use of "Vi" service.
      </Paragraph>

      <Title level={4}>10. Governing Law and Jurisdiction</Title>
      <Paragraph>
        These Terms of Use shall be governed by and construed in accordance with
        the laws of the country where "Vi" is based. Any dispute arising out of
        or in connection with these Terms of Use shall be subject to the
        exclusive jurisdiction of the courts in that country.
      </Paragraph>

      <Title level={4}>11. Changes to Terms of Use</Title>
      <Paragraph>
        "Vi" reserves the right to modify these Terms of Use at any time and
        without notice. Your continued use of "Vi" service following any changes
        to these Terms of Use constitutes your acceptance of the changes.
      </Paragraph>
    </>
  );

  const paypalRef = useRef();

  useEffect(() => {
    if (window.paypal && paypalRef.current.children.length === 0) {
      window.paypal
        .Buttons({
          style: { color: "blue" },
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: "18", // replace this with the amount
                  },
                },
              ],
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then(function (details) {
              alert(
                "Transaction completed by " + details.payer.name.given_name
              );
            });
          },
        })
        .render(paypalRef.current);
    }
  }, []);

  return (
    <Layout className="layout">
      <Header style={{ color: "#fff", textAlign: "center", display: "flex" }}>
        <img className="logo" src={logo} alt="logo" />
        <Title
          style={{ color: "white", fontSize: "20px", alignSelf: "center" }}
        >
          Vi - Ai Chat bot
        </Title>
        {/* <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1">Vi</Menu.Item>
        </Menu> */}
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <Breadcrumb
          items={[
            {
              title: "Home",
            },
            {
              title: (
                <>
                  <RobotOutlined /> <span> Vi chat</span>
                </>
              ),
            },
          ]}
          style={{ margin: "16px 0" }}
        ></Breadcrumb>
        <div className="site-layout-content">
          <Title level={2}>Meet Vi</Title>
          <p className="subTitle">Your Digital Marketing Expert</p>
          <Paragraph className="content">
            Vi is a digital marketing tool developed by Wivo powered by advanced
            AI technology. Subscribing to Vi will allow you to improve your
            digital marketing and content generation Via WhatsApp.
          </Paragraph>
          <Title level={4}>Subscription Fee:</Title>
          <div style={{ textAlign: "center", margin: "0 auto" }}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                width: "150px",
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "-10px",
              }}
            >
              <span>
                <del style={{ fontSize: "12px" }}>$25</del>
              </span>{" "}
              <span style={{ fontSize: "21px" }}> $18.00</span>/month
            </p>
          </div>
          <Checkbox
            style={{ fontSize: "11px", paddingLeft: "7px" }}
            checked={isChecked}
            disabled={isCheckboxDisabled}
            onChange={handleCheckboxChange}
          >
            I agree to the Terms and Conditions
          </Checkbox>
          <Collapse style={{ width: "80%", margin: "10px auto 0 auto" }}>
            <Panel header="Terms of Use" key="1">
              <div style={{ height: "200px", overflow: "auto" }}>{terms}</div>
            </Panel>
          </Collapse>

          <div
            style={{
              maxWidth: "200px",
              margin: "auto",
              paddingTop: "20px",
              display: isChecked ? "block" : "none",
            }}
            ref={paypalRef}
          ></div>
          <div style={{ width: "80%", margin: "auto" }}>
            <p style={{ fontSize: "12px" }}>
              Subscribe today and take your digital marketing to the next level.
            </p>
          </div>
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>Wivo Olas ©2023</Footer>
    </Layout>
  );
};

export default App;
